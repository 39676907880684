<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <!--    <div class="row">-->
    <!--      <div class="col-sm-12"><h1>My Profile</h1></div>-->
    <!--    </div>-->
    <div
      v-if="$data.status != 'success'"
      class="row"
    >
      <div class="col-sm-12">
        <ErrorFormField
          :msg-type="$data.status"
          :msg-array="$data.messages"
        />
      </div>
    </div>
    <div
      v-else
      class="row g-3"
    >
      <div class="col-md-12">
        <div class="cover-wrapper bg-light position-relative img-thumbnail">
          <div class="avatar-wrapper">
            <img
              v-if="$data.userDetails.image"
              :src="$data.userDetails.image"
              class="img-thumbnail w-100 h-100 img-fluid rounded-5"
            >
            <div class="bottom-right">
              <a
                href="#"
                class="link-secondary fs-3"
                title="edit avatar"
                data-bs-toggle="modal"
                data-bs-target="#avatarModal"
              >
                <i class="fa fa-edit" />
              </a>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div
            v-if="$data.userDetails.aboutus"
            class="col-md-12 text-center text-muted"
          >
            {{ $data.userDetails.aboutus }}
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-secondary d-flex">
            <h1 class="d-inline-flex flex-grow-1">
              Basic information
            </h1>
            <div
              v-if="isMyProfile()"
              class="d-inline-flex align-self-end"
            >
              <!-- Button trigger modal -->
              <button
                type="button"
                class="btn btn-dark"
                data-bs-toggle="modal"
                data-bs-target="#avatarModal"
              >
                <i class="fa fa-image" /> Edit Avatar
              </button>
              <router-link
                to="/edit-profile"
                class="btn btn-secondary"
              >
                <i class="fa fa-edit" /> Edit Profile
              </router-link>
              <!--              <a href="#" class="link-secondary fs-3"><i class="fa fa-edit"></i> Edit</a>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <div class="card">
              <div class="card-header d-flex">
                <div class="text-primary flex-grow-1 fs-3">
                  Video Introduce
                </div>
              </div>
              <div class="card-body">
                <div class="ratio ratio-16x9">
                  <iframe
                    src="https://www.youtube.com/embed/iDXJ-qd0eTQ?rel=0"
                    title="YouTube video"
                    allowfullscreen
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card text-start">
              <div class="card-header d-flex">
                <div class="text-primary flex-grow-1 fs-3">
                  Contact
                </div>
              </div>
              <div class="card-body">
                <!--            <h3 class="card-title text-primary line-under-text">Basic information</h3>-->
                <div class="card-text">
                  <div class="text-start">
                    <b>{{ $data.userDetails.first_name + ' ' + $data.userDetails.last_name }}</b>
                    <div>
                      <i class="fa fa-user-cog text-primary" /> Gender :
                      <span v-if="$data.userDetails.gender == 1">Male </span>
                      <span v-if="$data.userDetails.gender == 2">Female </span>
                    </div>
                    <div>
                      <i class="fa fa-calendar-check text-secondary" /> Born in
                      {{ $data.userDetails.dob }}
                    </div>
                    <div>
                      <i class="fa fa-calendar-times text-primary" /> Joined in
                      {{ $data.userDetails.created_ts }}
                    </div>
                    <div>
                      <i class="fa fa-language text-secondary" /> I can speak :
                      {{ $data.userDetails.language }}
                    </div>
                    <div>
                      <i class="fa fa-language text-secondary" /> Timezone :
                      {{ $data.userDetails.timezone }}
                    </div>
                  </div>
                </div>
                <h4 class="card-title text-primary line-under-text mb-0">
                  Emergency Contact
                </h4>
                <div class="card-text">
                  <b>{{ $data.userDetails.emergency_full_name }}</b>
                  <div>
                    <i class="fa fa-id-badge text-primary" /> Relationship:
                    {{ $data.userDetails.emergency_relationship }}
                  </div>
                  <div>
                    <i class="fa fa-mail-bulk text-secondary" />Email:
                    {{ $data.userDetails.emergency_email }}
                  </div>
                  <div>
                    <i class="fa fa-phone text-primary" /> Phone:
                    {{ $data.userDetails.emergency_phone }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="card">
              <div class="card-header">
                <div class="text-primary flex-grow-1 fs-3">
                  Your interest
                </div>
              </div>
              <div class="card-body">
                <div
                  v-if="$data.userDetails.interests"
                  class="card-text"
                >
                  {{ $data.userDetails.interests }}
                </div>
                <div
                  v-else
                  class="card-text"
                >
                  There's no data
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-5">
          <div class="col-md-4" />
        </div>

        <hr>
        <div class="card mt-3">
          <div class="card-header d-flex">
            <div class="text-primary flex-grow-1 fs-3">
              Reviews
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <ul class="nav nav-pills justify-content-end d-inline-flex align-self-end flex-fill">
                <li class="nav-item">
                  <a
                    :class="{ 'nav-link cur-pointer': true, active: $data.reviewActive == 'all' }"
                    aria-current="page"
                    data-review-tab="all"
                    @click="onReviewTabClick"
                  >All</a>
                </li>
                <li class="nav-item">
                  <a
                    :class="{
                      'nav-link cur-pointer': true,
                      active: $data.reviewActive == 'seeker',
                    }"
                    data-review-tab="seeker"
                    @click="onReviewTabClick"
                  >Seeker</a>
                </li>
                <li class="nav-item">
                  <a
                    :class="{
                      'nav-link cur-pointer': true,
                      active: $data.reviewActive == 'traveller',
                    }"
                    data-review-tab="traveller"
                    @click="onReviewTabClick"
                  >Traveller</a>
                </li>
              </ul>
            </div>

            <div class="card-text">
              There's no reviews
            </div>
          </div>
        </div>
        <hr>
        <div class="card mt-3">
          <div class="card-header d-flex">
            <div class="text-primary flex-grow-1 fs-3">
              References
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex">
              <ul class="nav nav-pills justify-content-end d-inline-flex align-self-end flex-fill">
                <li class="nav-item">
                  <a
                    :class="{
                      'nav-link cur-pointer': true,
                      active: $data.referenceActive == 'all',
                    }"
                    aria-current="page"
                    data-reference-tab="all"
                    @click="onReferenceTabClick"
                  >All</a>
                </li>
                <li class="nav-item">
                  <a
                    :class="{
                      'nav-link cur-pointer': true,
                      active: $data.referenceActive == 'received',
                    }"
                    data-reference-tab="received"
                    @click="onReferenceTabClick"
                  >Received</a>
                </li>
                <li class="nav-item">
                  <a
                    :class="{
                      'nav-link cur-pointer': true,
                      active: $data.referenceActive == 'given',
                    }"
                    data-reference-tab="given"
                    @click="onReferenceTabClick"
                  >Given</a>
                </li>
              </ul>
            </div>

            <div class="card-text">
              There's no reference
            </div>
            <div class="card-text text-end">
              <button
                type="button"
                class="btn btn-outline-secondary text-primary"
              >
                <i class="fa fa-pen" /> Ask for reference
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <AvatarEditor
    :image="$data.userDetails.image"
    @updateAvatar="updateAvatar"
  />
</template>

<script>
import { defineAsyncComponent } from 'vue';
// import AuthHandler from '../../common/AuthHandler';
import ErrorFormField from '../../common/error/ErrorFormField';
// import { tuiObjTreprTheme } from '../../common/CommonData.js';
// import jQuery from 'jquery';
// import ImageEditor from 'tui-image-editor';
//eslint-disable-next-line
// const FileSaver = require('file-saver');
//eslint-disable-next-line
// let bootstrap = require('bootstrap');
//eslint-disable-next-line
// const $ = jQuery.noConflict();
// import AvatarEditor from './modal/AvatarEditor';
//eslint-disable-next-line
let bootstrap = require('bootstrap');

export default {
  name: 'UserProfile',
  components: {
    ErrorFormField,
    AvatarEditor: defineAsyncComponent(() => import('./modal/AvatarEditor')),
  },
  data() {
    return {
      reviewActive: 'all',
      referenceActive: 'all',
      userDetails: {},
      avatar: '',
      status: '',
      messages: [],
      ajaxLoad: {
        avatarUpload: false,
      },
    };
  },
  computed: {},
  async created() {
    // let tmpState = this.$store.stage;
    if (this.$store.state.token === undefined) {
      this.$router.push('/');
      return;
    }
    let unique_id = this.$route.params.unique_id;
    // console.log('unique_id: ' + unique_id);
    let self = this;
    // console.log("state $store ", this.$store.state)
    let apiUrl = process.env.VUE_APP_API_URL;
    const formData = 'unique_id=' + encodeURIComponent(unique_id);
    let token = this.$store.state.token;

    let headersObject = new Headers({
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    // await fetch(apiUrl + 'user/get-profile', {
    await fetch(apiUrl + 'user/get-profile', {
      method: 'POST',
      headers: headersObject,
      body: formData,
    })
      .then((response) => response.json())
      .then((resData) => {
        // console.log('Success:', resData);
        // let messages = resData.messages
        self.$data.status = resData.status;

        if (resData.status == 'error') {
          alert('error while geting data ' + resData.message);
          return;
        }

        if (resData.status == 'warning') {
          self.$data.userDetails = null;
          self.$data.messages = resData.messages;
          return;
        }

        self.$data.userDetails = resData.userDetails;
      })
      .catch(function (err) {
        console.log('error', err);
      });
  },
  mounted() {},
  methods: {
    onReviewTabClick(e) {
      this.$data.reviewActive = e.currentTarget.getAttribute('data-review-tab');
    },
    onReferenceTabClick(e) {
      this.$data.referenceActive = e.currentTarget.getAttribute('data-reference-tab');
    },
    isMyProfile() {
      return this.$route.params.unique_id == this.$store.state.unique_id;
    },
    updateAvatar(newImagePath) {
      console.log('updateAvatar', newImagePath);
      this.$data.userDetails.image = newImagePath;
    },
  },
};
</script>

<style scoped>
.cover-wrapper {
  width: 100%;
  height: 435px !important;
}
.avatar-wrapper {
  width: 180px;
  height: 180px;
  bottom: 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 30px);
}

.line-under-text {
  text-decoration: underline;
  -webkit-text-decoration-color: #f58555; /* Safari */
  text-decoration-color: #f58555;
}
h5.line-under-text:after {
  top: 40px;
}
h3.line-under-text:after {
  top: 47px;
}
.bottom-right {
  position: absolute;
  bottom: 5px;
  right: 5px;
  font-size: 1rem;
  opacity: 0.5;
}
</style>
<style scoped lang="scss">
//@import "../assets/css/tui-image-editor.min.css"; node_modules/tui-color-picker/dist/tui-color-picker.css
//#tui-image-editor {
//  height: 800px !important;
//}
</style>
